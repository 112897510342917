import { autoinject } from "aurelia-framework";
import { ZoneReportViewModelDto } from "models/ZoneReportViewModelDto";
import { ZonePlanReportService } from "services/ZonePlanReportService";
import { PlanReportHeaderData } from "resources/plan-report-header/PlanReportHeaderData";
import { Subscription, EventAggregator } from "aurelia-event-aggregator";
import { ReportStatus } from "models/ReportStatus";
import { ZoneReportUpdated } from "resources/plan-report-header/ZoneReportUpdated";

@autoinject
export class ZoneReportView {
    reportid: number;
    zoneReport: ZoneReportViewModelDto;
    isDownloadingList = false;
    isDownloadingDetail = false;
    isResettingPlan = false;
    isResettingReport = false;
    isRecalculatingPlan = false;
    isRecalculatingYPlanFromQ = false;
    isRecalculatingReport = false;
    isRecalculatingYReportFromQ = false;
    subscription: Subscription;
    constructor(public service: ZonePlanReportService, public ea?: EventAggregator) { }

    activate(params: {reportid: number}) {
        this.reportid = params.reportid;
    }

    async attached() {
        this.subscription = this.ea.subscribe(ZoneReportUpdated, _ => this.loadReport(1));
        await this.loadReport(1);
    }

    detached() {
        this.subscription.dispose();
    }

    SingleTimeLastPeriodValuesUpdate(lastPeriodValuesUpdateNeeded: number) {
        /*
        Calls `updateReportLastPeriodFromModel()` for a single time
        when the report status is `Report Not Submitted`.

        `updateReportLastPeriodFromModel()` automatically calls `loadReport()`
        which causes the circular calling.
        */
        if( window.localStorage && lastPeriodValuesUpdateNeeded === 1 && this.zoneReport.reportStatus !== ReportStatus.Submitted ) {
            if( !window.localStorage.getItem('firstLoad') ) {
                this.service.updateReportLastPeriodFromModel(this.reportid);
                window.localStorage['firstLoad'] = true;
            }
            else {
                window.localStorage.removeItem('firstLoad');
            }
        };
    }

    async getZoneReport(lastPeriodValuesUpdateNeeded: number) {
        this.zoneReport = await this.service.getReport(this.reportid);
        this.SingleTimeLastPeriodValuesUpdate(lastPeriodValuesUpdateNeeded);
    }

    async loadReport(lastPeriodValuesUpdateNeeded: number) {
        this.getZoneReport(lastPeriodValuesUpdateNeeded);
    }

    get headerData() {
        if(!this.zoneReport) return undefined;
        return new PlanReportHeaderData("Zone Report", this.reportid, this.zoneReport.description, this.zoneReport.reportStatus, this.zoneReport.organization, this.zoneReport.reportingPeriod);
    }
}
